@import './colors';
@import './dimensions';
@import './vinka-table';
@import './forms';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/font-awesome/scss/font-awesome';
@import './vehicle-list';
@import './import';
@import './quill-wrapper.scss';

/**
 * Main page template.
 */

@media (min-width: 1500px) {
    .container {
        max-width: 90%;
    }
}

body {
    background-color: gray;
}

.hidden {
    display: none;
}

.login {
    position: absolute;
    right: 40pt;
    top: 7pt;

    a {
        color: white;
    }
}

.detail-view, .side-view {
    margin-top: $margin-md;
    margin-bottom: $margin-sm;
}

.application {

    background-color: #eeeeee;
    height: 100%;
    box-shadow: 5px 0px 5px 0px $shadow-color;

    header {
        height: $header-height;
        color: white;
        box-shadow: 0px 5px 5px 0px $shadow-color;
        position: relative;
        background-image: url("/garage/img/tiet-lg.jpg");

        .background {
            height: 100%;
            border: 1px solid $primary-color;
            background-color: $primary-color-transparent;
        }

        .app-name {
            padding: 1em;
        }

        .app-title {
            color: white;
            vertical-align: sub;
        }

        .title {
            position: absolute;
            font-weight: bold;
            margin-top: 10pt;
            margin-left: 6pt;
            font-size: 1.7em;
        }

        .settings {
            position: absolute;
            right: 6pt;
            top: 10pt;
            margin-top: 6pt;

            .toggle {
                cursor: pointer;
                font-size: 29pt;
                color: white;
            }

            .menu {
                display: none;
                position: absolute;
                right: 0;
                top: 120%;
                background-color: white;
                color: gray;
                z-index: 10;
                box-shadow: 0 5px 5px 0 $shadow-color;
                user-select: none;
                -webkit-user-select: none;

                &.show {
                    display: block;
                }

                .menu-item {
                    display: block;
                    padding: 0.5em 1em;
                    &:hover {
                        background-color: $shadow-color;
                        color: white;
                    }
                }

                .menu-hr {
                    display: block;
                    margin: 0;
                    border-bottom: 1px solid #ced4da;
                    border-top: 0;
                }
            }
        }
    }

    .page {
        .tab-content {
            border: 1px solid red;
            padding: 10pt;
            margin-bottom: 10pt;
            background-color: #ffffff;
            border: 1px solid #dddddd;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        }

        .tab-content.vehicle-inactive {
            background-color: rgb(219, 219, 219);
        }
    }

    // todo:
    .main-area {
        .data-form {
            margin-top: $margin-xs;
        }
    }

    .navbar-brand {
        height: 38px;
        margin: 0.3125rem 1rem 0.3125rem 0;
        padding: 0 0 0 140px;
        background: url('/garage/img/VINKA-W_smaller.png') no-repeat left center;
        font-size: 1rem;
        line-height: 38px;
        white-space: nowrap;
        color: white;
        vertical-align: sub;
    }

    .navbar-nav {
        flex-direction: row;

        .nav-link {
            padding: 0.5rem;
            color:  white;
            cursor: pointer;
            opacity: .8;

            &.active, &:hover {
                text-decoration: underline;
                opacity: 1;
            }
        }
    }

    .nav-link {
        color:  $primary-color;
    }

    button {
        margin-right: 1em;
        background-color: white;
        border: 1px solid $primary-color;
        color:  white;
        background: -webkit-linear-gradient(#f0b7a1 0%, #8c3310 96%, #bf6e4e 100%);
        background: -o-linear-gradient(#f0b7a1 0%, #8c3310 96%, #bf6e4e 100%);
        background: linear-gradient(#f0b7a1 0%, #8c3310 96%, #bf6e4e 100%);

        &[disabled] {
            background-color: transparent;
            &:hover {
                background-color: transparent;
                color:  $primary-color;
                border: 1px solid $primary-color;
            }
        }

        &.btn-sm {
            margin: 0.3em;
        }

        &:hover {
            background-color: $primary-color-transparent;
            border: 1px solid rgba(154, 63, 32, 0.8);
        }

        &.btn-primary {
            background-color: rgba(154, 63, 32, 0.2);
        }
    }

    code.inactive {
        background-color: #f5f5f5;
        color: $primary-color;
        padding: 0.25rem 0.5rem;
        font-size: 1.25rem;
    }
}

/**
 * Login page.
 */
.login-page {
    text-align: center;
    .large-login-btn {
        padding: 5em;
        button {
            margin: 1em;
            padding: 1em;
            font-size: 1em;
            border-radius: 5px;
        }
    }

    form {
        padding: 2em;
        margin-top: 10%;
        border-radius: 5pt;
        margin-left: auto;
        margin-right: auto;
        box-shadow: rgba(0,0,0,0.2) 0px 0px 3px inset;
    }
}

/**
 * Components.
 */

.dropdown {
    &.small {
        select {
            height: 22pt;
            font-size: 120%;
            padding-top: 4pt;
        }
    }
}

/**
 * Services.
 */
.small-modal-overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.small-modal-message, .small-modal-dialog {
    position: fixed;
    background-color: #ffffff;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.25);
    text-align: center;
    border-radius: 12pt;

    img {
        position: absolute;
        top: 30%;
        left: 5%;
    }
    .title {
        font-weight: bold;
        font-size: 20pt;
        margin-top: 5%;
    }
}

.small-modal-message {
    width: 30em;
    height: 14em;
    left: 30%;
    top: 25%;
}

.small-modal-dialog {
    width: 50%;
    height: 16%;
    left: 25%;
    top: 25%;
    button {
        width: 100pt;
    }
}

/**
 * Map
 */

.leaflet-container {
    border: 1px solid rgba(0,0,0,0.3);
    width: 100%;
    height: 500px;
    margin-bottom: 2ex;
    cursor: crosshair;
}

/**
 * Shift page.
 */
.shift-group {

    border: 1px solid rgba(0,0,0,0.3);
    padding: 0.2em 1em;
    border-radius: 4pt;
    margin-left: 0.5em;
    cursor: pointer;

    &.joined {
        background-color: green;
        border-color: black;
        color: white;
    }
}

.expired, .not-started {
    color: red;
}


.vinka-flex-column {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    max-height: 100%;

    .vinka-flex-column-head {
        flex-shrink: 0;
    }

    .vinka-flex-column-body {
        overflow: auto;
    }
}

.vinka-dropdown-content {
    position: absolute;
    z-index: 1;
    max-height: 200px;
    overflow: auto;
    background-color: #ffffff;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);

    .vinka-dropdown-row {
        &:hover {
            background-color: rgba(73, 81, 89, 0.6);
            color: #ffffff;
        }
    }
}

.vinka-fix-dropdown {
    position: relative;

    .vinka-dropdown-content {
        width: 80%;
        margin-left: 10%;

        input {
            width: 100%;
            margin: 0;
        }
    }
}

.ember-select {
    width: 80%;
    margin-left: 10%;

    .es-control {
        height: 30px;
        padding: 1px 2px;
        border-color: #767676;
        border-radius: 2px;
    }

    input {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    &.es-focus > .es-control {
        border: 2px solid #000;
        padding: 0 1px;
        box-shadow: none;
    }

    &:not(.es-focus) input {
        width: 1% !important;
    }
}

.ui-autocomplete {
    z-index: 1000 !important;
}
