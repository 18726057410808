$primary-color: rgb(154, 63, 32);
$primary-color-transparent: rgba(154, 63, 32, 0.7);
$shadow-color: rgba(0,0,0,0.5);
// todo: consider secondary color to be more different from primary
$secondary-color: rgb(142, 86, 69);
$secondary-color-transparent: rgba(142, 86, 69, 0.1);
$disabled-color: #bcb9b9;

$theme-colors: (
  "primary": $primary-color,
  "secondary": $secondary-color,
  "danger": #ff4136
);