
.vehicle-list {
    height: calc(100vh - #{$header-height} - 2pt);
    // padding-top: $margin-md;
    padding-bottom: $margin-sm;
    display: flex;
    flex-flow: column;

    .list-group-item {
        height: 30pt;
        padding-top: 4pt;
    }

    .search {
        margin-top: $margin-xs;
        margin-bottom: $margin-xxs;
    }

    .list {
        margin-top: $margin-xs;
        flex: 2;
        overflow-y: auto;
        .dirty {
            font-weight: bold;
            font-style: italic;
            &:before {
                color: red;
                content: "* "
            }
        }

        .inactive {
            text-decoration: line-through;
        }
    }

    .buttons {
        margin-top: 15pt;
        margin-bottom: 10pt;
    }
    .current {
        color: white;
        background-color: $secondary-color;
    }
    .hidden {
        display: none;
    }
}