
table {
    font-size: 90%;

    td {
        // box-shadow: rgba(0,0,0,0.2) 0px 0px 3px inset;
    }

    th {
        background-color: $primary-color-transparent;
        color: white;
    }
}

.table .sorting {
    float: right;

    .control {
        padding-left: 4px;
        padding-right: 4px;
        color: rgba(255, 255, 255, 0.5);

        &:hover {
            color: rgb(255,255,255);
            cursor: pointer;
        }

        &.main-sort {
            color: rgb(255, 255, 255);
        }
    }
}

/**
 * Table
 */

 .table-active {
    color: white;
    background-color: #8e5745;
 }

 .table-scroll-wrapper {
     overflow: auto;
     width: 100%;
     max-height: 70vh;
     border: 1px solid #dee2e6;
 }
