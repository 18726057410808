.flex-container {
  position: absolute;
  left: 1em;
  right: 1em;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px;
}

.import-container {
  position: relative;
  top: 1em;
  flex-direction: column;
  height: 50%;

  span.import-result {
    margin-right: 1em;
  }

  .import-buttons {
    display: flex;
    padding: 1em;

    .btn {
      margin-left: 1em;
      margin-right: 1em;
    }

    button {
      flex: 1 0 auto;
    }
  }

  .card {
    .card-subtitle {
      margin-top: 1em;
      margin-bottom: 1.5em;
    }
    label {
      margin-right: 1em;
    }
    .select-vehicle-label {
      margin-left: 1.5em;
    }

    .worker-slider {
      background-color: transparent;
      border: none;
    }
  }

  .import-results {
    height: 100%;
    overflow-y: auto;
  }

  .import-area {
    align-items: stretch;
    border: dashed 5px;
    border-color: #373a3c;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.drag-active {
  background-color: rgba(#777777, .5);
}
